import React from "react";
import RequestForm from "./requestForm";
import JsonView from "./jsonView";

class Hero extends React.Component {
  render() {
    return (
      <section className="hero">
        <div className="container">
          <div className="hero-inner">
            <div className="hero-copy">
              <h1 className="hero-title mt-0">
                Glean into any app internals with powerful APIs
              </h1>
              <p className="hero-paragraph">
                Detonate app binary to provide source code level details
                including app author, signature and build information.
              </p>
              <div>
                <RequestForm></RequestForm>
              </div>
            </div>
            <div className="hero-copy" style={{ paddingTop: "10px" }}>
              <JsonView></JsonView>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
