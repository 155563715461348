import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="site-footer">
        <div className="container">
          <div className="site-footer-inner has-top-divider">
            <ul className="footer-links list-reset">
              <li>
                <a href="mailto:support@appdetonator.run" target="_top">
                  Contact
                </a>
              </li>
            </ul>

            <div className="footer-copyright">
              © 2019 App Detonator, all rights reserved
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
