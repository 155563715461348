import React from "react";

const Mailchimp = ({ url, u, id, emailValue, onChange }) => {
  return (
    <div>
      <form action={url} method="POST" noValidate target="_blank">
        <input type="hidden" name="u" value={u} />
        <input type="hidden" name="id" value={id} />
        <div className="footer-form newsletter-form field field-grouped">
          <div className="control control-expanded">
            <input
              type="email"
              name="EMAIL"
              value={emailValue}
              onChange={onChange}
              className="input"
              placeholder="Email address"
            />
          </div>
          <div className="control">
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button button-primary button-block button-shadow"
            />
          </div>
        </div>
        <div id="mce-responses" className="clear">
          <div
            className="response"
            id="mce-error-response"
            style={{ display: "none" }}
          ></div>
          <div
            className="response"
            id="mce-success-response"
            style={{ display: "none" }}
          ></div>
        </div>

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_7e21e43b0cb5585b5f647ddb8_8fa05c63f7"
            tabIndex="-1"
            defaultValue=""
          />
        </div>
      </form>
    </div>
  );
};

export default Mailchimp;
