import React, { Component } from "react";
import ReactJson from "react-json-view";
import { analyze } from "../services/analysisService";

class JsonView extends Component {
  state = {
    sampleJson: []
  };
  async componentDidMount() {
    const key = "UXh7bpkdRwYRm_Z4PZRiJ3kAMPzYmPaZrrd_Nk4Y2kKG33bLOx-ASg";
    const url = "https://storage.googleapis.com/media53/jack2.apk";
    const { data: sampleJson } = await analyze(key, url);
    this.setState({ sampleJson });
  }

  prestyle = {
    backgroundColor: "#fff",
    color: "#37404d",
    borderLeft: "2px solid #808ba6",
    padding: "12px"
  };
  exampleUrl =
    "https://api.appdetonator.run/analyze?key=<API_KEY>&url=https://example.apk";
  render() {
    return (
      <React.Fragment>
        <pre style={this.prestyle}> {this.exampleUrl}</pre>
        <pre style={this.prestyle}>
          <ReactJson
            src={this.state.sampleJson}
            style={{ paddingRight: "500px" }}
            collapsed={1}
            displayDataTypes={false}
            displayObjectSize={true}
            indentWidth={3}
          />
        </pre>
      </React.Fragment>
    );
  }
}

export default JsonView;
