import React from "react";

class Header extends React.Component {
  render() {
    return (
      <header className="site-header">
        <div className="container">
          <div className="site-header-inner">
            <h3 className="site-title m-0">
              <a
                href="#"
                style={{
                  textDecoration: "none"
                }}
              >
                <span style={{ color: "#e12262" }}>App</span>
                <span style={{ color: "#58678C" }}>Detonator</span>
              </a>
            </h3>
            <div className="header-links">
              <a
                href="https://appdetonator.gitbook.io/appdetonator/"
                style={{ fontSize: ".75rem" }}
                target="_blank"
              >
                DOCS
              </a>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
