import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "./common/form";
import { request } from "../services/requestService";

class RequestForm extends Form {
  state = {
    data: { email: "" },
    errors: {}
  };

  schema = {
    email: Joi.string()
      .min(5)
      .max(255)
      .required()
      .email()
      .label("Email")
  };

  doSubmit = async () => {
    try {
      const data = { ...this.state.data };
      const { data: result } = await request(data.email);
      if (result.emailVerificationSent)
        toast("An email has been sent, please verify");
      data.email = "";
      this.setState({ data });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="hero-form field field-grouped">
          {this.renderInput("email", "Your email", "email")}
          {this.renderButton("Early access")}
        </div>
      </form>
    );
  }
}

export default RequestForm;
