import React from "react";

class Testimonials extends React.Component {
  render() {
    return (
      <section className="testimonials section">
        <div className="container">
          <div className="testimonials-inner section-inner">
            <div className="testimonials-header text-center">
              <div className="container-sm">
                <h2
                  className="section-title mt-0"
                  style={{ marginBottom: "16px" }}
                >
                  Supported Platforms
                </h2>
                <p className="section-paragraph">Android. iOS coming soon.</p>
              </div>
            </div>
            {/* <div className="testimonials-wrap">
              <div className="testimonial text-xs is-revealing">
                <div className="testimonial-inner">
                  <div className="testimonial-main">
                    <div className="testimonial-header">
                      <img
                        className="mb-16"
                        src="dist/images/testimonial-01.png"
                        alt="Testimonial"
                      />
                    </div>
                    <div className="testimonial-body">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt.
                      </p>
                    </div>
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-link">
                      <a href="#">@martajones</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial text-xs is-revealing">
                <div className="testimonial-inner">
                  <div className="testimonial-main">
                    <div className="testimonial-header">
                      <img
                        className="mb-16"
                        src="dist/images/testimonial-02.png"
                        alt="Testimonial"
                      />
                    </div>
                    <div className="testimonial-body">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt.
                      </p>
                    </div>
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-link">
                      <a href="#">@michealpahm</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial text-xs is-revealing">
                <div className="testimonial-inner">
                  <div className="testimonial-main">
                    <div className="testimonial-header">
                      <img
                        className="mb-16"
                        src="dist/images/testimonial-03.png"
                        alt="Testimonial"
                      />
                    </div>
                    <div className="testimonial-body">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt.
                      </p>
                    </div>
                  </div>
                  <div className="testimonial-footer">
                    <div className="testimonial-link">
                      <a href="#">@markbrown</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonials;
