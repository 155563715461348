import React from "react";
import { ToastContainer } from "react-toastify";
import Header from "./components/header";
import Footer from "./components/footer";
import Hero from "./components/hero";
import Features from "./components/features";
import Testimonials from "./components/testimonials";
import Newsletter from "./components/newsletter";
import "react-toastify/dist/ReactToastify.css";

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <div className="body-wrap boxed-container">
          <Header></Header>
          <main>
            <Hero></Hero>
            <Features></Features>
            <Testimonials></Testimonials>
            <Newsletter></Newsletter>
          </main>

          <Footer></Footer>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
