import React from "react";
import MailchimpForm from "./mailchimpForm";

class Newsletter extends React.Component {
  render() {
    return (
      <section className="newsletter section text-light">
        <div className="container-sm">
          <div className="newsletter-inner section-inner">
            <div className="newsletter-header text-center">
              <h2 className="section-title mt-0">Stay in the know</h2>
              <p className="section-paragraph"></p>
            </div>
            <div>
              <MailchimpForm></MailchimpForm>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Newsletter;
