import React, { Component } from "react";
import Mailchimp from "./common/mailchimp";

class MailchimpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: ""
    };
  }

  handleChange = ({ currentTarget: input }) => {
    this.setState({ emailValue: input.value });
  };
  render() {
    return (
      <div>
        <Mailchimp
          url="https://gmail.us5.list-manage.com/subscribe/post"
          u="7e21e43b0cb5585b5f647ddb8"
          id="8fa05c63f7"
          emailValue={this.state.emailValue}
          onChange={this.handleChange}
        ></Mailchimp>
      </div>
    );
  }
}

export default MailchimpForm;
